import React from "react"

function TestimonialA() {
  return (
    <svg viewBox="0 0 266 150" fill="none">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <rect
        x={25}
        y={57}
        width={104}
        height={62}
        rx={1}
        fill="var(--base-200)"
      />
      <circle cx={39.5} cy={105.5} r={7.5} fill="var(--base-400)" />
      <path
        d="M52 108a1 1 0 011-1h24a1 1 0 010 2H53a1 1 0 01-1-1z"
        fill="var(--base-500)"
      />
      <rect
        x={52}
        y={101}
        width={43}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={32}
        y={75}
        width={76}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={32}
        y={81}
        width={88}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={32}
        y={87}
        width={83}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <path
        d="M38.641 63h-2.182a.354.354 0 00-.36.349v2.119c0 .192.161.349.36.349h1.044c-.014.554-.146.999-.398 1.333-.198.263-.498.481-.9.653a.344.344 0 00-.177.468l.258.53c.084.17.29.245.468.17.475-.2.876-.452 1.204-.758.399-.375.672-.797.82-1.268.148-.472.222-1.115.222-1.93v-1.666a.354.354 0 00-.359-.349zM32.761 68.97c.47-.199.869-.451 1.198-.757.403-.375.678-.796.826-1.264.148-.467.222-1.112.222-1.934v-1.666a.354.354 0 00-.359-.349h-2.183a.354.354 0 00-.359.349v2.119c0 .192.161.349.36.349h1.044c-.014.554-.146.999-.398 1.333-.198.263-.498.481-.9.653a.344.344 0 00-.177.468l.258.529c.083.17.29.245.468.17z"
        fill="var(--base-500)"
      />
      <rect
        x={137}
        y={57}
        width={104}
        height={62}
        rx={1}
        fill="var(--base-200)"
      />
      <circle cx={151.5} cy={105.5} r={7.5} fill="var(--base-400)" />
      <path
        d="M164 108a1 1 0 011-1h24a1 1 0 010 2h-24a1 1 0 01-1-1z"
        fill="var(--base-500)"
      />
      <rect
        x={164}
        y={101}
        width={43}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={144}
        y={75}
        width={76}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={144}
        y={81}
        width={88}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={144}
        y={87}
        width={83}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <path
        d="M150.641 63h-2.182a.354.354 0 00-.359.349v2.119c0 .192.16.349.359.349h1.044c-.014.554-.146.999-.398 1.333-.198.263-.498.481-.899.653a.344.344 0 00-.178.468l.258.53c.084.17.29.245.468.17.475-.2.876-.452 1.204-.758.399-.375.672-.797.82-1.268.148-.472.222-1.115.222-1.93v-1.666a.354.354 0 00-.359-.349zM144.761 68.97c.47-.199.869-.451 1.198-.757.403-.375.678-.796.826-1.264.148-.467.222-1.112.222-1.934v-1.666a.354.354 0 00-.359-.349h-2.183a.353.353 0 00-.358.349v2.119c0 .192.16.349.358.349h1.045c-.014.554-.146.999-.398 1.333-.198.263-.498.481-.899.653a.344.344 0 00-.178.468l.257.529c.084.17.291.245.469.17z"
        fill="var(--base-500)"
      />
      <rect
        x={107}
        y={31}
        width={52}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
    </svg>
  )
}

export default TestimonialA;
