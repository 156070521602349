import React from "react";

import BlogA from "./blog/a";
import BlogB from "./blog/b";
import BlogC from "./blog/c";
import BlogD from "./blog/d";
import BlogE from "./blog/e";

import ContactA from "./contact/a";
import ContactB from "./contact/b";
import ContactC from "./contact/c";

import ContentA from "./content/a";
import ContentB from "./content/b";
import ContentC from "./content/c";
import ContentD from "./content/d";
import ContentE from "./content/e";
import ContentF from "./content/f";
import ContentG from "./content/g";
import ContentH from "./content/h";

import CTAA from "./cta/a";
import CTAB from "./cta/b";
import CTAC from "./cta/c";
import CTAD from "./cta/d";

import EcommerceA from "./ecommerce/a";
import EcommerceB from "./ecommerce/b";
import EcommerceC from "./ecommerce/c";

import FeatureA from "./feature/a";
import FeatureB from "./feature/b";
import FeatureC from "./feature/c";
import FeatureD from "./feature/d";
import FeatureE from "./feature/e";
import FeatureF from "./feature/f";
import FeatureG from "./feature/g";
import FeatureH from "./feature/h";

import FooterA from "./footer/a";
import FooterB from "./footer/b";
import FooterC from "./footer/c";
import FooterD from "./footer/d";
import FooterE from "./footer/e";

import GalleryA from "./gallery/a";
import GalleryB from "./gallery/b";
import GalleryC from "./gallery/c";

import HeaderA from "./header/a";
import HeaderB from "./header/b";
import HeaderC from "./header/c";
import HeaderD from "./header/d";

import HeroA from "./hero/a";
import HeroB from "./hero/b";
import HeroC from "./hero/c";
import HeroD from "./hero/d";
import HeroE from "./hero/e";
import HeroF from "./hero/f";

import PricingA from "./pricing/a";
import PricingB from "./pricing/b";

import StatisticA from "./statistic/a";
import StatisticB from "./statistic/b";
import StatisticC from "./statistic/c";

import StepA from "./step/a";
import StepB from "./step/b";
import StepC from "./step/c";

import TeamA from "./team/a";
import TeamB from "./team/b";
import TeamC from "./team/c";

import TestimonialA from "./testimonial/a";
import TestimonialB from "./testimonial/b";
import TestimonialC from "./testimonial/c";

export default function getIcons() {
  return {
    Header: {
      HeaderA: <HeaderA />,
      HeaderB: <HeaderB />,
      HeaderC: <HeaderC />,
      HeaderD: <HeaderD />,
    },
    Hero: {
      HeroA: <HeroA />,
      HeroB: <HeroB />,
      HeroC: <HeroC />,
      HeroD: <HeroD />,
      HeroE: <HeroE />,
      HeroF: <HeroF />,
    },
    Blog: {
      BlogA: <BlogA />,
      BlogB: <BlogB />,
      BlogC: <BlogC />,
      BlogD: <BlogD />,
      BlogE: <BlogE />,
    },
    Contact: {
      ContactA: <ContactA />,
      ContactB: <ContactB />,
      ContactC: <ContactC />,
    },
    Content: {
      ContentA: <ContentA />,
      ContentB: <ContentB />,
      ContentC: <ContentC />,
      ContentD: <ContentD />,
      ContentE: <ContentE />,
      ContentF: <ContentF />,
      ContentG: <ContentG />,
      ContentH: <ContentH />,
    },
    CTA: {
      CTAA: <CTAA />,
      CTAB: <CTAB />,
      CTAC: <CTAC />,
      CTAD: <CTAD />,
    },
    Ecommerce: {
      EcommerceA: <EcommerceA />,
      EcommerceB: <EcommerceB />,
      EcommerceC: <EcommerceC />,
    },
    Feature: {
      FeatureA: <FeatureA />,
      FeatureB: <FeatureB />,
      FeatureC: <FeatureC />,
      FeatureD: <FeatureD />,
      FeatureE: <FeatureE />,
      FeatureF: <FeatureF />,
      FeatureG: <FeatureG />,
      FeatureH: <FeatureH />,
    },
    Footer: {
      FooterA: <FooterA />,
      FooterB: <FooterB />,
      FooterC: <FooterC />,
      FooterD: <FooterD />,
      FooterE: <FooterE />,
    },
    Gallery: {
      GalleryA: <GalleryA />,
      GalleryB: <GalleryB />,
      GalleryC: <GalleryC />,
    },
    Pricing: {
      PricingA: <PricingA />,
      PricingB: <PricingB />,
    },
    Statistic: {
      StatisticA: <StatisticA />,
      StatisticB: <StatisticB />,
      StatisticC: <StatisticC />,
    },
    Step: {
      StepA: <StepA />,
      StepB: <StepB />,
      StepC: <StepC />,
    },
    Team: {
      TeamA: <TeamA />,
      TeamB: <TeamB />,
      TeamC: <TeamC />,
    },
    Testimonial: {
      TestimonialA: <TestimonialA />,
      TestimonialB: <TestimonialB />,
      TestimonialC: <TestimonialC />,
    },
  };
}
