import React from "react"

function ContentA() {
  return (
    <svg viewBox="0 0 266 150" fill="none">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <rect
        x={113}
        y={120}
        width={40}
        height={10}
        rx={2}
        fill="var(--main-500)"
      />
      <rect
        x={81}
        y={31}
        width={104.391}
        height={4}
        rx={2}
        fill="var(--base-500)"
      />
      <rect
        x={96}
        y={20}
        width={74}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect
        x={85}
        y={39}
        width={97.365}
        height={4}
        rx={2}
        fill="var(--base-500)"
      />
      <rect
        x={26}
        y={73}
        width={28}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect x={26} y={79} width={34} height={2} rx={1} fill="var(--base-500)" />
      <rect x={26} y={84} width={38} height={2} rx={1} fill="var(--base-500)" />
      <rect x={26} y={89} width={24} height={2} rx={1} fill="var(--main-500)" />
      <rect
        x={20}
        y={62}
        width={1}
        height={39}
        rx={0.5}
        fill="var(--base-400)"
      />
      <rect
        x={86}
        y={73}
        width={28}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect x={86} y={79} width={34} height={2} rx={1} fill="var(--base-500)" />
      <rect x={86} y={84} width={38} height={2} rx={1} fill="var(--base-500)" />
      <rect x={86} y={89} width={24} height={2} rx={1} fill="var(--main-500)" />
      <rect
        x={80}
        y={62}
        width={1}
        height={39}
        rx={0.5}
        fill="var(--base-400)"
      />
      <rect
        x={146.136}
        y={73}
        width={28.636}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={146.136}
        y={79}
        width={34.773}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={146.136}
        y={84}
        width={38.864}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={146.136}
        y={89}
        width={24.546}
        height={2}
        rx={1}
        fill="var(--main-500)"
      />
      <rect
        x={140}
        y={62}
        width={1.023}
        height={39}
        rx={0.511}
        fill="var(--base-400)"
      />
      <rect
        x={207.136}
        y={73}
        width={28.636}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={207.136}
        y={79}
        width={34.773}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={207.136}
        y={84}
        width={38.864}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={207.136}
        y={89}
        width={24.546}
        height={2}
        rx={1}
        fill="var(--main-500)"
      />
      <rect
        x={201}
        y={62}
        width={1.023}
        height={39}
        rx={0.511}
        fill="var(--base-400)"
      />
    </svg>
  )
}

export default ContentA;
