import React from "react"

function FooterE() {
  return (
    <svg fill="none" viewBox="0 0 266 150">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <rect
        x={63.385}
        y={76}
        width={32.308}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={63.385}
        y={84}
        width={21.231}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={63.385}
        y={91}
        width={14.769}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={63.385}
        y={98}
        width={25.846}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={63.385}
        y={105}
        width={21.231}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={20}
        y={76}
        width={32.308}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={20}
        y={84}
        width={21.231}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={20}
        y={91}
        width={14.769}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={20}
        y={98}
        width={25.846}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={20}
        y={105}
        width={21.231}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={107.692}
        y={76}
        width={32.308}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={163}
        y={76}
        width={32.308}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={107.692}
        y={84}
        width={21.231}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={107.692}
        y={91}
        width={14.769}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={107.692}
        y={98}
        width={25.846}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={107.692}
        y={105}
        width={21.231}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <path
        d="M217 86a2 2 0 012-2h25a2 2 0 012 2v6a2 2 0 01-2 2h-25a2 2 0 01-2-2v-6z"
        fill="var(--main-500)"
      />
      <rect
        x={163}
        y={84}
        width={50}
        height={10}
        rx={2}
        fill="var(--base-400)"
      />
      <path fill="var(--base-200)" d="M0 119h266v31H0z" />
      <circle cx={28.5} cy={134.5} r={8.5} fill="var(--main-500)" />
      <rect
        x={45}
        y={133}
        width={30}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={198}
        y={133}
        width={48}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
    </svg>
  )
}

export default FooterE;
