import React from "react"

function PricingA() {
  return (
    <svg viewBox="0 0 266 150" fill="none">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <rect
        x={96}
        y={22}
        width={74}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect x={87} y={31} width={92} height={4} rx={2} fill="var(--base-500)" />
      <path
        d="M65.5 83a.5.5 0 010 1h-41a.5.5 0 010-1h41z"
        fill="var(--base-300)"
      />
      <rect
        x={20.5}
        y={69.5}
        width={49}
        height={58}
        rx={1.5}
        stroke="var(--base-400)"
      />
      <circle cx={26} cy={89} r={2} fill="var(--base-500)" />
      <rect
        x={24}
        y={77}
        width={18}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <rect x={30} y={88} width={34} height={2} rx={1} fill="var(--base-500)" />
      <circle cx={26} cy={95} r={2} fill="var(--base-500)" />
      <rect x={30} y={94} width={34} height={2} rx={1} fill="var(--base-500)" />
      <circle cx={26} cy={101} r={2} fill="var(--base-500)" />
      <rect
        x={30}
        y={100}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={26} cy={107} r={2} fill="var(--base-500)" />
      <rect
        x={30}
        y={106}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={24}
        y={73}
        width={10}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <path
        d="M24 116.5a2 2 0 012-2h38a2 2 0 012 2v4a2 2 0 01-2 2H26a2 2 0 01-2-2v-4z"
        fill="var(--base-500)"
      />
      <path
        d="M124.5 83a.5.5 0 010 1h-41a.5.5 0 010-1h41z"
        fill="var(--base-300)"
      />
      <rect
        x={79.5}
        y={69.5}
        width={49}
        height={58}
        rx={1.5}
        stroke="var(--main-500)"
      />
      <circle cx={85} cy={89} r={2} fill="var(--base-500)" />
      <rect
        x={83}
        y={77}
        width={18}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <rect x={89} y={88} width={34} height={2} rx={1} fill="var(--base-500)" />
      <circle cx={85} cy={95} r={2} fill="var(--base-500)" />
      <rect x={89} y={94} width={34} height={2} rx={1} fill="var(--base-500)" />
      <circle cx={85} cy={101} r={2} fill="var(--base-500)" />
      <rect
        x={89}
        y={100}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={85} cy={107} r={2} fill="var(--base-500)" />
      <rect
        x={89}
        y={106}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={83}
        y={73}
        width={10}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <path
        d="M183.5 83a.5.5 0 010 1h-41a.5.5 0 010-1h41z"
        fill="var(--base-300)"
      />
      <rect
        x={138.5}
        y={69.5}
        width={49}
        height={58}
        rx={1.5}
        stroke="var(--base-400)"
      />
      <circle cx={144} cy={89} r={2} fill="var(--base-500)" />
      <rect
        x={142}
        y={77}
        width={18}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <rect
        x={148}
        y={88}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={144} cy={95} r={2} fill="var(--base-500)" />
      <rect
        x={148}
        y={94}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={144} cy={101} r={2} fill="var(--base-500)" />
      <rect
        x={148}
        y={100}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={144} cy={107} r={2} fill="var(--base-500)" />
      <rect
        x={148}
        y={106}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={142}
        y={73}
        width={10}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <path
        d="M142 116.5a2 2 0 012-2h38a2 2 0 012 2v4a2 2 0 01-2 2h-38a2 2 0 01-2-2v-4z"
        fill="var(--base-500)"
      />
      <path
        d="M83 116.5a2 2 0 012-2h38a2 2 0 012 2v4a2 2 0 01-2 2H85a2 2 0 01-2-2v-4z"
        fill="var(--main-500)"
      />
      <path
        d="M242.5 83a.5.5 0 010 1h-41a.5.5 0 010-1h41z"
        fill="var(--base-300)"
      />
      <rect
        x={197.5}
        y={69.5}
        width={49}
        height={58}
        rx={1.5}
        stroke="var(--base-400)"
      />
      <circle cx={203} cy={89} r={2} fill="var(--base-500)" />
      <rect
        x={201}
        y={77}
        width={18}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <rect
        x={207}
        y={88}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={203} cy={95} r={2} fill="var(--base-500)" />
      <rect
        x={207}
        y={94}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={203} cy={101} r={2} fill="var(--base-500)" />
      <rect
        x={207}
        y={100}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <circle cx={203} cy={107} r={2} fill="var(--base-500)" />
      <rect
        x={207}
        y={106}
        width={34}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={201}
        y={73}
        width={10}
        height={2}
        rx={1}
        fill="var(--solid-900)"
      />
      <path
        d="M201 116.5a2 2 0 012-2h38a2 2 0 012 2v4a2 2 0 01-2 2h-38a2 2 0 01-2-2v-4z"
        fill="var(--base-500)"
      />
      <path
        d="M118 43.5h30a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-30a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z"
        stroke="var(--main-500)"
      />
      <path fill="var(--main-500)" d="M117 43h16v7h-16z" />
    </svg>
  )
}

export default PricingA;
