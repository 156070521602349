import React from "react"

function StepC() {
  return (
    <svg viewBox="0 0 266 150" fill="none">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <path
        d="M24 28.5a.5.5 0 011 0v93a.5.5 0 01-1 0v-93z"
        fill="var(--base-400)"
      />
      <path
        d="M26 40.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        fill="var(--main-500)"
      />
      <path
        d="M47 43a1 1 0 011-1h54a1 1 0 010 2H48a1 1 0 01-1-1z"
        fill="var(--base-500)"
      />
      <rect
        x={47}
        y={36}
        width={35}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <circle cx={36.5} cy={40.5} r={6.5} fill="var(--main-200)" />
      <path
        d="M26 63.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        fill="var(--main-500)"
      />
      <path
        d="M47 66a1 1 0 011-1h54a1 1 0 010 2H48a1 1 0 01-1-1z"
        fill="var(--base-500)"
      />
      <rect
        x={47}
        y={59}
        width={35}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <circle cx={36.5} cy={63.5} r={6.5} fill="var(--main-200)" />
      <path
        d="M26 86.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        fill="var(--main-500)"
      />
      <path
        d="M47 89a1 1 0 011-1h54a1 1 0 010 2H48a1 1 0 01-1-1z"
        fill="var(--base-500)"
      />
      <rect
        x={47}
        y={82}
        width={35}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <circle cx={36.5} cy={86.5} r={6.5} fill="var(--main-200)" />
      <path
        d="M26 109.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        fill="var(--main-500)"
      />
      <path
        d="M47 112a1 1 0 011-1h54a1 1 0 010 2H48a1 1 0 01-1-1z"
        fill="var(--base-500)"
      />
      <rect
        x={47}
        y={105}
        width={35}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <circle cx={36.5} cy={109.5} r={6.5} fill="var(--main-200)" />
      <path
        d="M175.792 89h26.544a3.785 3.785 0 003.792-3.778V58.778A3.785 3.785 0 00202.336 55h-26.544A3.785 3.785 0 00172 58.778v26.444A3.785 3.785 0 00175.792 89zm0 0l20.856-20.778 9.48 9.445m-20.856-12.278a2.838 2.838 0 01-2.844 2.833 2.838 2.838 0 01-2.844-2.833 2.838 2.838 0 012.844-2.833 2.838 2.838 0 012.844 2.833z"
        stroke="var(--base-500)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StepC;
