import React from "react"

function EcommerceB() {
  return (
    <svg viewBox="0 0 266 150" fill="none">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <path
        d="M52.792 91h26.544a3.785 3.785 0 003.792-3.778V60.778A3.785 3.785 0 0079.336 57H52.792A3.785 3.785 0 0049 60.778v26.444A3.785 3.785 0 0052.792 91zm0 0l20.856-20.778 9.48 9.445M62.272 67.389a2.839 2.839 0 01-2.844 2.833 2.839 2.839 0 01-2.844-2.833 2.839 2.839 0 012.844-2.833 2.839 2.839 0 012.844 2.833z"
        stroke="var(--base-500)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={133}
        y={42}
        width={74}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect
        x={133}
        y={105}
        width={26}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect
        x={133}
        y={35}
        width={38}
        height={3}
        rx={1.5}
        fill="var(--base-400)"
      />
      <rect
        x={133}
        y={51}
        width={26}
        height={3}
        rx={1.5}
        fill="var(--main-500)"
      />
      <rect
        x={133}
        y={64}
        width={92}
        height={4}
        rx={2}
        fill="var(--base-500)"
      />
      <circle cx={241} cy={108} r={5} fill="var(--base-300)" />
      <path
        d="M245.5 94a.5.5 0 010 1h-112a.5.5 0 010-1h112z"
        fill="var(--base-300)"
      />
      <rect
        x={200}
        y={103}
        width={31}
        height={10}
        rx={2}
        fill="var(--main-500)"
      />
      <rect
        x={133}
        y={72}
        width={82}
        height={4}
        rx={2}
        fill="var(--base-500)"
      />
      <rect
        x={133}
        y={80}
        width={68}
        height={4}
        rx={2}
        fill="var(--base-500)"
      />
    </svg>
  )
}

export default EcommerceB;
