import React from "react"

function ContactA() {
  return (
    <svg fill="none" viewBox="0 0 266 150">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <rect
        x={153}
        y={30}
        width={93}
        height={90}
        rx={2}
        fill="var(--base-200)"
      />
      <rect
        x={162}
        y={101}
        width={75}
        height={10}
        rx={2}
        fill="var(--main-500)"
      />
      <rect
        x={162}
        y={66}
        width={75}
        height={30}
        rx={2}
        fill="var(--base-400)"
      />
      <rect
        x={162}
        y={51}
        width={75}
        height={10}
        rx={2}
        fill="var(--base-400)"
      />
      <rect
        x={162}
        y={40}
        width={40}
        height={4}
        rx={2}
        fill="var(--solid-900)"
      />
      <path
        d="M89 71.682C89 81.546 76.5 90 76.5 90S64 81.546 64 71.682c0-3.364 1.317-6.59 3.661-8.968A12.41 12.41 0 0176.5 59a12.41 12.41 0 018.839 3.714A12.776 12.776 0 0189 71.682z"
        stroke="var(--base-500)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.5 75.91c2.301 0 4.167-1.894 4.167-4.228 0-2.335-1.866-4.228-4.167-4.228-2.301 0-4.167 1.893-4.167 4.228 0 2.334 1.866 4.227 4.167 4.227z"
        stroke="var(--base-500)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ContactA;
